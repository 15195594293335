import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { Subtext } from '@components/async-form';
import { showToast } from '@components/toaster';
import { useCurrentTenant } from '@components/router/session-context';
import { Dispatch, StateUpdater } from 'preact/hooks';
import { showError } from '@components/app-error';
import { pluralize } from 'shared/formatting';
import { TenantRow } from 'server/types';
import { AccountTabContent } from '../account-tab-content';
import { CORE_DOMAIN } from 'shared/consts';
import { Button } from '@components/buttons';
import { showDialog } from '@components/dialog';

export type ExistingTenant = RpxResponse<typeof rpx.tenants.getMyTenant>;

function BtnMoveCourses(props: {
  onClick(): void;
  fromDomain: string;
  toDomain: string;
  isPrimary?: boolean;
}) {
  return (
    <Button onClick={props.onClick} class={props.isPrimary ? `btn-primary` : 'btn border-gray-200'}>
      Move all courses{' '}
      <strong class={`mx-1 ${props.isPrimary ? '' : ''}`}>from {props.fromDomain}</strong> to
      <em class="ml-1">{props.toDomain}</em>
    </Button>
  );
}

/**
 * The current user has a live, already-configured tenant, so we'll just show
 * them basic links and some options to move courses between the tenant and
 * core.
 */
export function ManageExistingTenant({
  myTenant,
  setMyTenant,
}: {
  myTenant: NonNullable<ExistingTenant>;
  setMyTenant: Dispatch<StateUpdater<ExistingTenant>>;
}) {
  const currentTenant = useCurrentTenant();
  const migrateCourses = async (opts: {
    toTenant: Pick<TenantRow, 'id' | 'name'>;
    fromTenant: Pick<TenantRow, 'id' | 'name'>;
  }) => {
    try {
      const confirmed = await showDialog({
        mode: 'warn',
        title: `Move your courses from ${opts.fromTenant.name}?`,
        confirmButtonText: `Move my courses`,
        children: (
          <>
            Are you sure you want to move your courses from <strong>{opts.fromTenant.name}</strong>{' '}
            to <em>{opts.toTenant.name}</em>? This can be undone.
          </>
        ),
      });
      if (!confirmed) {
        return;
      }

      await rpx.tenants.migrateCourses({
        fromTenantId: opts.fromTenant.id,
        toTenantId: opts.toTenant.id,
      });

      setMyTenant((t) => {
        const tenant = t!;
        const total = tenant.numCoreCourses + tenant.numTenantCourses;
        return {
          ...tenant,
          numCoreCourses: opts.toTenant.id === currentTenant.id ? total : 0,
          numTenantCourses: opts.toTenant.id === currentTenant.id ? 0 : total,
        };
      });
      showToast({
        title: 'Courses migrated',
        message: 'Your courses have been moved.',
        type: 'ok',
      });
    } catch (err) {
      showError(err);
    }
  };

  const siteURL = `https://${myTenant.domain}`;
  const adminURL = `${siteURL}/admin/settings`;
  let title = '';
  const isPrimary = !!myTenant.numCoreCourses && !myTenant.numTenantCourses;
  if (!isPrimary) {
    title = `Manage site "${myTenant.name}"`;
  } else if (myTenant.numCoreCourses) {
    title = `One last step: move your courses to your Ruzuku Pro site.`;
  } else {
    title = `Your Ruzuku Pro site is ready to go!`;
  }

  return (
    <AccountTabContent title={title}>
      <div class="flex flex-col gap-6">
        {(!!myTenant.numCoreCourses || !!myTenant.numTenantCourses) && (
          <div class="border p-6 rounded-md">
            {myTenant.numTenantCourses > 0 && (
              <div>
                <Subtext>
                  You have {myTenant.numTenantCourses}{' '}
                  {pluralize('course', myTenant.numTenantCourses)} at {myTenant.domain}.
                </Subtext>
                <BtnMoveCourses
                  fromDomain={myTenant.domain}
                  toDomain={CORE_DOMAIN}
                  onClick={() =>
                    migrateCourses({
                      fromTenant: myTenant,
                      toTenant: currentTenant,
                    })
                  }
                />
              </div>
            )}
            {myTenant.numCoreCourses > 0 && (
              <div>
                <Subtext>
                  You have {myTenant.numCoreCourses} {pluralize('course', myTenant.numCoreCourses)}{' '}
                  at {CORE_DOMAIN}.
                </Subtext>
                <BtnMoveCourses
                  fromDomain={CORE_DOMAIN}
                  toDomain={myTenant.domain}
                  isPrimary={isPrimary}
                  onClick={() =>
                    migrateCourses({
                      fromTenant: currentTenant,
                      toTenant: myTenant,
                    })
                  }
                />
              </div>
            )}
          </div>
        )}
        <div class="border p-6 rounded-md flex flex-col gap-4">
          <div>
            <p>Your site is ready and can be viewed by visiting:</p>
            <a href={siteURL}>{siteURL}</a>
          </div>
          <div>
            <p>Manage your site here:</p>
            <a href={adminURL}>{adminURL}</a>
          </div>
          <p>
            To change your domain or delete your site,{' '}
            <a href={`mailto:support@ruzuku.com`}>please contact Ruzuku support</a>.
          </p>
        </div>
      </div>
    </AccountTabContent>
  );
}
