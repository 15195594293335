/**
 * A global stack of items (dialogs, toasts, etc) to be rendered.
 */

import { signal } from '@preact/signals';

type GlobalComponent<T> = (props: {
  resolve(result: T | undefined): unknown;
}) => JSX.Element | null;

let id = 0;
const nextId = () => `${++id}`;

/**
 * The stack of global components to be displayed.
 */
const globalStack = signal<Array<{ key: string; Render(): JSX.Element }>>([]);

/**
 * Hid all other modals.
 */
export async function hideModals() {
  globalStack.value = [];
}

/**
 * Add a new component to the global stack.
 */
export async function showGlobal<T>(Render: GlobalComponent<T>) {
  const key = nextId();
  return new Promise<T | undefined>((resolve) => {
    const hide = (result: T | undefined) => {
      globalStack.value = globalStack.value.filter((x) => x.key !== key);
      resolve(result);
    };

    globalStack.value = [
      ...globalStack.value,
      {
        key,
        Render() {
          return <Render resolve={hide} />;
        },
      },
    ];
  });
}

/**
 * A root-level component that renders the global stack.
 */
export function GlobalComponents() {
  if (!globalStack.value.length) {
    return null;
  }

  return (
    <>
      {globalStack.value.map(({ key, Render }) => (
        <Render key={key} />
      ))}
    </>
  );
}
