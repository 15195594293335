import { showError } from '@components/app-error';
import { AsyncForm, FormGroup } from '@components/async-form';
import { Case } from '@components/conditional';
import { DateInput } from '@components/date-picker';
import { router } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { Spinner } from '@components/spinner';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { useEffect, useState } from 'preact/hooks';
import { Course } from 'server/types';
import { URLS } from 'shared/urls';
import { CoursePrivacySettings, CoursePrivacyState } from '@components/course-privacy-settings';
import { ComponentChildren } from 'preact';
import { DialogFooter, DialogHeader, StandardDialog } from '@components/dialog';

interface Props {
  course: Pick<Course, 'id' | 'title'>;
  onCancel: () => void;
}

const store = rpx.instantCourses;

export function CopyCourseTemplateModal({ course, onCancel }: Props) {
  const { terminology } = useCurrentTenant();
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <StandardDialog onClose={onCancel}>
      <CopyCourseTemplateForm
        onIsProcessing={setIsProcessing}
        course={course}
        header={<DialogHeader title={<>Create {terminology.course} from template</>} />}
        footer={
          <DialogFooter
            isLoading={isProcessing}
            confirmButtonText={<>Create {terminology.course}</>}
          />
        }
      />
    </StandardDialog>
  );
}

export function CopyCourseTemplateForm({
  course,
  header,
  footer,
  onIsProcessing,
}: {
  onIsProcessing?(isProcessing: boolean): void;
  course: Props['course'];
  header?: ComponentChildren;
  footer?: ComponentChildren;
}) {
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const { id, title } = course;
  const [isLoading, setIsLoading] = useState(true);
  const [modulesData, setModulesData] = useState<
    RpxResponse<typeof store.getCourseModules> | undefined
  >();
  const [newDate, setNewDate] = useState<Date | undefined>(() => dayjs().add(7, 'days').toDate());
  const [featuresState, setFeaturesState] = useState<CoursePrivacyState>({
    hidePeople: false,
    hideDiscussions: false,
    chatEnabled: true,
    restrictStudentDiscussions: false,
  });

  const modules = modulesData?.modules ?? [];
  const firstDate = modulesData?.firstDate;

  useEffect(() => {
    async function fetchFirstDates() {
      try {
        const result = await store.getCourseModules({ courseId: id });
        setModulesData(result);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchFirstDates();
  }, [id]);

  async function copyCourse(values: { title: string }) {
    try {
      onIsProcessing?.(true);
      const { newCourseId, firstLessonId } = await store.copyInstantCourse({
        ...featuresState,
        id,
        title: values.title || title,
        startDateDiff:
          firstDate?.date && newDate ? dayjs(newDate).diff(firstDate.date, 'minute') : undefined,
      });
      router.goto(
        URLS.guide.lesson({
          courseId: newCourseId,
          lessonId: firstLessonId,
        }),
      );
    } catch (err) {
      onIsProcessing?.(false);
      showError(err);
    }
  }

  return (
    <AsyncForm onSubmit={copyCourse}>
      {header}
      <section class="flex flex-col gap-8">
        <div>
          <label class="block mb-2">Enter the title of your new {terminology.course}.</label>
          <FormGroup prop="title" class="w-full">
            <input type="text" placeholder={title} name="title" class="ruz-input" />
          </FormGroup>
        </div>

        <Case when={!isLoading} fallback={<Spinner />}>
          {firstDate?.date && (
            <div>
              <label class="block mb-2">
                Set the first {firstDate.type} date of this {terminology.course}. We'll adjust the
                remaining
                {terminology.module}, message and {terminology.meeting} dates accordingly.
              </label>
              <div class="flex items-center">
                <DateInput name="newDate" value={newDate} onChange={setNewDate} includeTime />
              </div>
            </div>
          )}
        </Case>

        {modules.length > 0 && (
          <div class="flex flex-col">
            <h3 class="text-sm uppercase font-bold text-gray-500 mb-2">{terminology.Modules}</h3>
            <ol class="list-inside list-decimal">
              {modules.slice(0, 5).map((l) => (
                <li key={l.id} class="text-ellipsis overflow-hidden list-decimal">
                  {l.title}
                </li>
              ))}
            </ol>
            {modules.length > 5 && <footer class="mt-2">...and {modules.length - 5} more</footer>}
          </div>
        )}
        <CoursePrivacySettings state={featuresState} setState={setFeaturesState} />
      </section>
      {footer}
    </AsyncForm>
  );
}
