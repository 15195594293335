import { Toggle } from '@components/toggle';
import { AsyncForm } from '@components/async-form';
import { FullCourse } from 'server/types';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { BtnPrimary } from '@components/buttons';
import { showToast } from '@components/toaster';
import { useCurrentTenant } from '@components/router/session-context';
import { HeadingSecondary } from '@components/headings';
import { Dispatch, StateUpdater, useState } from 'preact/hooks';
import { partialStateUpdater } from 'client/lib/hooks';
import { invokeCourseChatToggledEvent } from '@components/chat-widget/events';
const store = rpx.courseSettings;

export interface CoursePrivacyState {
  hidePeople: boolean;
  hideDiscussions: boolean;
  chatEnabled: boolean;
  restrictStudentDiscussions: boolean;
}

export function CoursePrivacySettingsForm({
  course,
  onUpdate,
}: {
  course: FullCourse;
  onUpdate: (update: CoursePrivacyState) => void;
}) {
  const [state, setState] = useState<CoursePrivacyState>({
    hidePeople: course.hidePeople,
    hideDiscussions: course.hideDiscussions,
    chatEnabled: course.chatEnabled,
    restrictStudentDiscussions: course.restrictStudentDiscussions,
  });

  async function submit() {
    try {
      await store.setPrivacySettings({
        courseId: course.id,
        ...state,
      });
      showToast({
        title: 'Settings changed',
        message: 'Privacy & Community settings have been updated.',
        type: 'ok',
      });
      onUpdate(state);

      // If chat was enabled/disabled, send an event to the chat widget
      if (state.chatEnabled !== course.chatEnabled) {
        invokeCourseChatToggledEvent({
          courseId: course.id,
          enabled: state.chatEnabled,
        });
      }
    } catch (err) {
      showError(err);
    }
  }

  return (
    <AsyncForm class="flex flex-col gap-6" onSubmit={submit}>
      <HeadingSecondary>Privacy & Community settings</HeadingSecondary>
      <CoursePrivacySettings state={state} setState={setState} hideTitle />
      <BtnPrimary>Save</BtnPrimary>
    </AsyncForm>
  );
}

export function CoursePrivacySettings({
  state,
  setState,
  hideTitle,
}: {
  state: CoursePrivacyState;
  setState: Dispatch<StateUpdater<CoursePrivacyState>>;
  hideTitle?: boolean;
}) {
  const tenant = useCurrentTenant();
  const { terminology } = tenant;

  const updateState = partialStateUpdater(setState);

  return (
    <div class="flex flex-col gap-4">
      {!hideTitle && <h3 class="pt-4 border-t">Privacy & Community settings</h3>}
      <label class="flex cursor-pointer">
        <Toggle
          class="mt-1"
          name="displayPeople"
          checked={!state.hidePeople}
          onClick={() => updateState({ hidePeople: !state.hidePeople })}
        />
        <span class="flex flex-col ml-4">
          <span class="text-zinc-900 dark:text-gray-200 capitalize">{terminology.people} Page</span>
          <span class="text-zinc-500 dark:text-gray-400 mt-1">
            Show "{terminology.people}" page to students
          </span>
        </span>
      </label>
      <label
        class={`flex border-b pb-4 ${
          state.hidePeople ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        }`}
        title={
          state.hidePeople
            ? `${terminology.Course} chat cannot be enabled when the ${terminology.people} page is hidden.`
            : ''
        }
      >
        <Toggle
          class="mt-1"
          name="chatEnabled"
          checked={!state.hidePeople && state.chatEnabled}
          disabled={state.hidePeople}
          onClick={() => updateState({ chatEnabled: !state.chatEnabled })}
        />
        <span class="flex flex-col ml-4">
          <span class="text-zinc-900 dark:text-gray-200 capitalize">Chats</span>
          <span class="text-zinc-500 dark:text-gray-400 mt-1">
            Let students chat with each other
          </span>
        </span>
      </label>
      <label class="flex cursor-pointer">
        <Toggle
          class="mt-1"
          name="displayDiscussions"
          checked={!state.hideDiscussions}
          onClick={() => updateState({ hideDiscussions: !state.hideDiscussions })}
        />
        <span class="flex flex-col ml-4">
          <span class="text-zinc-900 dark:text-gray-200 capitalize">
            {terminology.discussions} Page
          </span>
          <span class="text-zinc-500 dark:text-gray-400 mt-1">
            Show "{terminology.discussions}" page to students
          </span>
        </span>
      </label>
      <label
        class={`flex ${state.hideDiscussions ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
      >
        <Toggle
          class="mt-1"
          name="restrictStudentDiscussions"
          checked={!state.restrictStudentDiscussions}
          disabled={state.hideDiscussions}
          onClick={() =>
            updateState({ restrictStudentDiscussions: !state.restrictStudentDiscussions })
          }
        />
        <span class="flex flex-col ml-4">
          <span class="text-zinc-900 dark:text-gray-200 capitalize">
            Allow students to create new discussions
          </span>
          <span class="text-zinc-500 dark:text-gray-400 mt-1">
            Disabling this prevents students from creating new discussions
          </span>
        </span>
      </label>
    </div>
  );
}
