import { ModalFormContext, showModalForm } from '@components/modal-form';
import { useContext, useState } from 'preact/hooks';
import { NewPriceForm } from '../components/new-price-form';
import { MetadataTab } from '../components/metadata-tab';
import { Price, priceStore } from './state';
import { showToast } from '@components/toaster';
import { rpx } from 'client/lib/rpx-client';
import { CORE_PRODUCT_ID } from 'shared/ids';
import { StandardDialog } from '@components/dialog';

const store = rpx.superadminPricing;

interface Props {
  folderId?: string;
  productId: string;
  setPrice(price: Price): void;
}

export function showNewPriceModal(props: Props) {
  return showModalForm(() => {
    const { resolve } = useContext(ModalFormContext);
    const hide = () => resolve(undefined);
    const [price, setPrice] = useState<Price | undefined>(undefined);

    return (
      <StandardDialog contentWidth onClose={hide}>
        {!price && (
          <NewPriceForm
            onCancel={hide}
            supportsStripe
            supportsPaypal={false}
            createPrice={async (opts) => {
              const { id } = await priceStore.createPrice({
                ...(opts as any),
                isCorePrice: true,
                productId: props.productId,
              });

              if (props.folderId) {
                await store.addFolderItems({
                  id: props.folderId,
                  items: [id],
                });
              }

              const price: Price = {
                ...opts,
                id,
                productId: props.productId,
                createdAt: new Date(),
                updatedAt: new Date(),
                type: 'price',
              };
              setPrice(price);
              props.setPrice(price);
              return price;
            }}
          />
        )}
        {price && (
          <MetadataTab
            metadata={price.metadata || {}}
            hideTier={price.productId !== CORE_PRODUCT_ID}
            onSave={async (metadata) => {
              await priceStore.updatePrice({
                priceId: price.id,
                productId: price.productId,
                metadata,
              });
              showToast({
                type: 'ok',
                title: 'Saved',
                message: `Saved price.`,
              });
              const newPrice = { ...price, metadata };
              props.setPrice(newPrice);
              resolve(newPrice);
            }}
          />
        )}
      </StandardDialog>
    );
  });
}
