import { useImageUrl } from 'client/utils/cdn';
import { Recording } from 'server/types';
import { isAudio, isVideo } from 'shared/media';
import { IcoPaperclip } from '@components/icons';
import { Case } from '@components/conditional';
import { VidstackPlayer } from '@components/vidstack-player';

interface Props {
  recording: Recording;
  allowDownload: boolean;
  hideTitle?: boolean;
}

function Video({ recording, allowDownload }: Props) {
  const videoUrl = useImageUrl(recording.url);
  const poster = useImageUrl(recording.poster);
  const downloadUrl = useImageUrl(recording.downloadUrl);

  if (!videoUrl) {
    return null;
  }

  return (
    <VidstackPlayer
      id={recording.id}
      url={videoUrl}
      poster={poster}
      captions={recording.hasCaptions ? `/captions/${recording.id}` : undefined}
      downloadUrl={allowDownload ? downloadUrl : undefined}
      type={recording.type}
    />
  );
}

function ChatFile({ recording }: Props) {
  const url = useImageUrl(recording.url);

  return (
    <a
      target="blank"
      href={url}
      class="bg-gray-100 dark:bg-gray-800 border border-gray-200 p-2 block rounded"
    >
      <IcoPaperclip class="w-4 h-4 mr-2 inline-block align-middle" />
      {recording.name}
    </a>
  );
}

export function RecordingPlayer(props: Props) {
  return (
    <div class="relative">
      {!props.hideTitle && props.recording.title && (
        <span class="text-base font-medium">{props.recording.title}</span>
      )}
      <Case
        when={isVideo(props.recording.type) || isAudio(props.recording.type)}
        fallback={<ChatFile {...props} />}
      >
        <Video {...props} />
      </Case>
    </div>
  );
}
